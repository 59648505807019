/** @jsx jsx */
import {jsx} from 'theme-ui'
import styled from '@emotion/styled/macro'
import Img from 'gatsby-image'
import {Link, graphql} from 'gatsby'

const Image = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: 2rem;
  img {
    border-radius: 2rem;
  }
  > div {
    position: static !important;
  }
  > div > div {
    position: static !important;
  }
`

const Overlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2rem;
  background-image: linear-gradient(
    30deg,
    ${(props) => props.theme.colors.primary} 0%,
    ${(props) => props.theme.colors.primary} 100%
  );
  color: ${(props) => props.theme.colors.white.light};
  opacity: 0.1;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  h2 {
    margin-bottom: 0;
  }
`

const Wrapper = styled.article`
  position: relative;
  z-index: 100;
  border-radius: ${(props) => props.theme.borderRadius.default};
  box-shadow: ${(props) => props.theme.shadows.feature.small.default};
  transition: ${(props) => props.theme.transitions.boom.transition};
  height: 20rem;
  margin: 3rem 0;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.feature.small.hover};
    transform: translateY(-12px);
    ${Overlay} {
      opacity: 0.9;
    }
  }
  flex-basis: calc(99.9% * 1 / 2 - 1rem);
  max-width: calc(99.9% * 1 / 2 - 1rem);
  width: calc(99.9% * 1 / 2 - 1rem);
  @media (max-width: 800px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    height: 18rem;
    &:first-of-type {
      margin-bottom: 2rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    height: 15rem;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem;
  z-index: 3;
  border-radius: ${(props) => props.theme.borderRadius.default};
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary};
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.5) 100%
    );
    z-index: -10;
    border-radius: ${(props) => props.theme.borderRadius.default};
    transition: opacity ${(props) => props.theme.transitions.default.duration};
  }
  &:hover {
    &:after {
      opacity: 0;
    }
  }
`

const ListItem = ({img, capTitle, date, path, key}) => (
  <Wrapper key={key}>
    <Image>
      <Img
        fluid={img}
        style={{
          borderRadius: '2rem',
        }}
      />
    </Image>
    <StyledLink to={path}>
      <span
        sx={{
          fontSize: '1.1rem',
          mb: 0,
          color: (t) => t.colors.gray[1],
        }}
      >
        {date}
      </span>
      <h1
        sx={{
          color: (t) => t.colors.gray[1],
          mt: '0',
          mb: 0,
          fontSize: '1.5rem',
          lineHeight: 1.25,
        }}
      >
        {capTitle}
      </h1>
    </StyledLink>

    <Overlay />
  </Wrapper>
)

export default ListItem

export const query = graphql`
  fragment BlogPost on PrismicBlogeintrag {
    uid
    last_publication_date(formatString: "DD.MM.YYYY", locale: "de-ch")
    data {
      cb_title
      post_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 524, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }

  fragment EventPost on PrismicEvents {
    uid
    isFuture
    data {
      cb_title
      event_date(formatString: "DD.MM.YYYY", locale: "de-ch")
      image {
        url
        alt
        localFile {
          childImageSharp {
            fluid(maxWidth: 524, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
